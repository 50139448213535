import React, { Component } from 'react'

// Bootstrap
import { Container } from 'react-bootstrap'

// Components
import HeaderNavbar from './Navbars/HeaderNavbar'
import Carousels from './Carousels'
import Features from './Features'

export default class LandingPage extends Component {

  render() {
    return (
      <Container>
        <HeaderNavbar></HeaderNavbar>
        <Carousels></Carousels>
        <Features></Features>
      </Container>
    )
  }
}
