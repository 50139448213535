import React, { Component } from 'react'

// pages
// import Index from "views/Index.js";
// import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "./components/views/LandingPage.jsx";
// import ProfilePage from "views/examples/ProfilePage.js";
// import RegisterPage from "views/examples/RegisterPage.js";

// Components
// import Admon from './components/Admon/Admon'
// import WillerAdmon from './components/Willer/WillerAdmon'
// import Products from './components/Admon/Products/Products'
// import Measures from './components/Admon/Measures/Measures'
// import Categorys from './components/Admon/Categorys/Categorys'
// import FilterProducts from './components/Order/FilterProducts'

// Routing
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"

class App extends Component{
  
  constructor() {
    super()
    this.state = {
    }
  }

  render() {
    
    return (   
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="lading_page" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App