import React, { Component } from 'react'
// Bootstrap
import { Carousel } from 'react-bootstrap'
// Import images
import CAROUSEL_BEACH_TENNIS_01 from './_images/carousel_beach_tennis_01.png'
import CAROUSEL_BEACH_TENNIS_02 from './_images/carousel_beach_tennis_02.png'
import CAROUSEL_BEACH_TENNIS_03 from './_images/carousel_beach_tennis_03.png'

export default class Carousels extends Component {
  render() {
    return (
        <Carousel>
            <Carousel.Item>
            <img
                className="d-block w-100"
                src={CAROUSEL_BEACH_TENNIS_01}
                alt="First slide"   
            />
                <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={CAROUSEL_BEACH_TENNIS_02}
                    alt="Second slide"
                />
            
                <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={CAROUSEL_BEACH_TENNIS_03}
                    alt="Third slide"
                />
            
                <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
  }
}
